import * as THREE from "three";
import React, { useRef, useEffect } from "react";
import { useThree, useFrame } from 'react-three-fiber';
import state from '../store';


const Effects = () => {
  const composer = useRef();
  const effect = useRef();
  const water = useRef();
  const bloom = useRef();
  const { gl, size, camera, scene } = useThree();

  useEffect(() => void composer.current.setSize(size.width, size.height), [
    size,
  ]);

  let last = state.top.current;
  useFrame(() => {
    const { top, effectFactor } = state;

    effectFactor.current = THREE.MathUtils.lerp(effectFactor.current, 0, 0.1);

    effect.current.factor = THREE.MathUtils.lerp(
      effect.current.factor,
      Math.max((top.current - last) / -100, effectFactor.current),
      0.1
    );
    bloom.current.strength = THREE.MathUtils.lerp(
      bloom.current.strength,
      Math.max(Math.abs((top.current - last) / 800), effectFactor.current),
      0.1
    );
    water.current.factor = THREE.MathUtils.lerp(
      water.current.factor,
      Math.max(Math.abs((top.current - last) / 60), effectFactor.current),
      0.1
    );
    last = top.current;
    gl.autoClear = true;
    composer.current.render();
  }, 1);

  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      <unrealBloomPass
        attachArray="passes"
        ref={bloom}
        args={[undefined, 0.0, 1, 0.0]}
      />
      <effectPass attachArray="passes" ref={effect} />
      <waterPass attachArray="passes" ref={water} />
    </effectComposer>
  );
};

export default Effects;