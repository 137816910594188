import * as THREE from "three";
import React, { createContext, useRef, useContext } from "react";
import { useFrame, useThree } from "react-three-fiber";
import state from "./store";

const offsetContext = createContext(0);

const Block = ({ children, offset, factor, ...props }) => {
  const { offset: parentOffset, sectionWidth } = useBlock();
  const ref = useRef();
  offset = offset !== undefined ? offset : parentOffset;
  useFrame(() => {
    const curY = ref.current.position.x;
    const curTop = state.top.current;
    ref.current.position.x = THREE.MathUtils.lerp(
      curY,
      -curTop * factor,
      0.1
    );
  });
  return (
    <offsetContext.Provider value={offset}>
      <group {...props} position={[sectionWidth * offset * factor, 0, 0]}>
        <group ref={ref}>{children}</group>
      </group>
    </offsetContext.Provider>
  );
}

const useBlock = () => {
  const { sections, pages } = state;
  const { size, viewport } = useThree();
  const offset = useContext(offsetContext);
  const viewportWidth = size.width;
  const viewportHeight = size.height;
  const canvasWidth = size.width;
  const canvasHeight = size.height;
  const mobile = size.width < 700;
  const margin = canvasWidth * (mobile ? 0.3 : 0.1);
  const contentMaxWidth = canvasWidth * (mobile ? 0.7 : 0.6);
  const sectionHeight = canvasHeight * ((pages - 1) / (sections - 1));
  const sectionWidth = canvasWidth * ((pages - 1) / (sections - 1));
  const offsetFactor = (offset + 1.0) / sections;
  return {
    viewport,
    offset,
    viewportWidth,
    viewportHeight,
    canvasWidth,
    canvasHeight,
    mobile,
    margin,
    contentMaxWidth,
    sectionHeight,
    sectionWidth,
    offsetFactor,
  };
}

export { Block, useBlock };
