import React, { useRef, useState, useEffect, createRef } from "react";
import * as THREE from "three";
import { useFrame } from "react-three-fiber";

import useImageCache from "../hooks/useImageCache";
import { Block, useBlock } from "../blocks";
import Plane from "./Plane";
import state from "../store";

// eslint-disable-next-line no-undef
const IMAGES_ALL = IMAGES;

const useImages = () => {
  const [images, setImages] = useState([]);
  const imageCache = useImageCache(IMAGES_ALL);

  useEffect(() => {
    if (images.length === 0) {
      const initialImages = Object.keys(imageCache)
        .splice(0, 6)
        .map((url) => ({
          cur: url,
          prev: url,
          transition: createRef(),
        }));
      initialImages.forEach((image) => {
        image.transition.current = 1.0;
      });
      setImages(initialImages);
    }

    const interval = setInterval(() => {
      const newImage = Object.keys(imageCache)[
        Math.floor(Math.random() * Object.keys(imageCache).length)
      ];

      if (
        images.every(({ cur, prev }) => cur !== newImage && prev !== newImage)
      ) {
        const i = Math.floor(Math.random() * images.length);

        setImages(
          images.map(({ cur, prev, ...rest }, index) =>
            index === i
              ? {
                  cur: newImage,
                  prev: cur,
                  ...rest,
                }
              : { cur, prev, ...rest }
          )
        );
        images[i].transition.current = 0.0;
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [imageCache, images]);

  return {
    images,
    imageCache,
  };
};

const Content = () => {
  const group = useRef();

  const { images, imageCache } = useImages();

  const { sectionWidth } = useBlock();
  useFrame(() => {
    const sz = THREE.MathUtils.lerp(
      group.current.scale.z,
      state.navZoom ? 0.6 : 1,
      0.1
    );
    group.current.scale.set(sz, sz, sz);
  });
  return (
    <group ref={group}>
      {images.map(({ cur, prev, transition }, index) => {
        return (
          <Block key={index} factor={1} offset={index}>
            <Plane
              map={imageCache[cur]}
              prevMap={imageCache[prev]}
              transition={transition}
              shift={100}
              frustumCulled={false}
              onClick={() => {
                state.ref.scrollLeft = sectionWidth * index;
              }}
            />
          </Block>
        );
      })}
    </group>
  );
};

export default Content;
