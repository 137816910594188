import * as THREE from "three";

let imageCache = { promise: null, resolved: false, images: {} };

const useImageCache = (urls) => {
  if (!imageCache.promise) {
    imageCache.promise = new Promise((resolve, reject) => {
      urls.forEach((url) => {
        const loader = new THREE.TextureLoader();

        loader.load(
          url,
          (data) => {
            imageCache.images[url] = data;
            imageCache.images[url].minFilter = THREE.LinearFilter;

            if (
              !imageCache.resolved &&
              Object.keys(imageCache.images).length >= 6
            ) {
              imageCache.resolved = true;
              resolve(imageCache.images);
            }
          },
          (e) => {
            console.log('Progress', e);
          },
          (err) => {
            if (!imageCache.resolved) reject(err);
          }
        );
      });
    });

    throw imageCache.promise;
  } else if (!imageCache.resolved) {
    throw imageCache.promise;
  } else {
    return imageCache.images;
  }
};

export default useImageCache;
