import * as THREE from "three";
import React, { Suspense, useState, useRef, useEffect } from "react";
import { useDrag } from "react-use-gesture";
import {
  Canvas,
  extend,
  useThree,
  useFrame,
  createPortal,
} from "react-three-fiber";
import { Circle } from "@react-three/drei";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass";
import { WaterPass } from "./components/WaterPass";
import { EffectPass } from "./components/EffectPass";
import { Block, useBlock } from "./blocks";
import Content from "./components/Content";
import Effects from "./components/Effects";
import state from "./store";

extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  WaterPass,
  UnrealBloomPass,
  FilmPass,
  EffectPass,
});

const Rect = ({ scale, color, ...props }) => {
  return (
    <group scale={scale}>
      <mesh {...props}>
        <circleBufferGeometry args={[1, 32]} />
        <meshBasicMaterial transparent color={color || "white"} />
      </mesh>
    </group>
  );
};

const Marker = () => {
  const ref = useRef();
  const [active, setActive] = useState(false);
  const [hovered, set] = useState(false);
  useEffect(() => {
    document.body.style.cursor = hovered ? "grab" : "auto";
    state.navZoom = hovered || active;
  }, [hovered, active]);
  const { viewportWidth } = useBlock();
  useFrame(() => {
    const s = THREE.MathUtils.lerp(
      ref.current.scale.x,
      active || hovered ? 18 : 10,
      0.1
    );
    ref.current.scale.set(s, s, s);
  });
  const bind = useDrag(({ offset: [x], ...props }) => {
    if (props.first) setActive(true);
    else if (props.last) setActive(false);
    state.ref.scrollLeft =
      (x / viewportWidth) * 2 * (viewportWidth * state.pages);
  });
  return (
    <group ref={ref} position={[0, 0, -2]}>
      <Rect
        {...bind()}
        onPointerOver={(e) => {
          e.stopPropagation();
          set(true);
        }}
        onPointerOut={() => set(false)}
        color="#dd0000"
      />
    </group>
  );
};

const Dot = () => {
  const [hovered, set] = useState(false);
  const { offset, sectionWidth } = useBlock();
  useEffect(
    () => void (document.body.style.cursor = hovered ? "pointer" : "auto"),
    [hovered]
  );
  return (
    <Rect
      scale={[5, 5, 5]}
      onPointerOver={() => set(true)}
      onPointerOut={() => set(false)}
      onClick={() => (state.ref.scrollLeft = offset * sectionWidth)}
    />
  );
};

const Map = () => {
  const { viewportHeight } = useBlock();
  return (
    <group position={[0, viewportHeight * -0.45, 0]}>
      <Marker />
      {new Array(6).fill().map((img, index) => (
        <Block key={index} factor={1 / state.sections / 2} offset={index}>
          <Dot />
        </Block>
      ))}
    </group>
  );
};

const HeadsUpDisplay = ({ children }) => {
  const [scene] = useState(() => new THREE.Scene());
  const { gl, camera } = useThree();
  useFrame(() => {
    gl.autoClear = false;
    gl.clearDepth();
    gl.render(scene, camera);
  }, 2);
  return createPortal(children, scene);
};

const Loader = ({ scale }) => {
  const circle1 = useRef();
  const circle2 = useRef();
  const circle3 = useRef();

  useFrame(() => {
    const m = new Date().getTime() * 0.005;
    if (circle1.current && circle2.current && circle3.current) {
      circle1.current.scale.set(
        0.3 * Math.sin(m) + 1.0,
        0.3 * Math.sin(m) + 1.0,
        1.0
      );
      circle2.current.scale.set(
        0.3 * Math.sin(m + Math.PI / 3.0) + 1.0,
        0.3 * Math.sin(m + Math.PI / 3.0) + 1.0,
        1.0
      );
      circle3.current.scale.set(
        0.3 * Math.sin(m + (2.0 * Math.PI) / 3.0) + 1.0,
        0.3 * Math.sin(m + (2.0 * Math.PI) / 3.0) + 1.0,
        1.0
      );
    }

    state.effectFactor.current = 0.4;
  });

  return (
    <group scale={scale}>
      <Circle
        ref={circle1}
        args={[0.1, 32]}
        position={[-0.5, 0, 0]}
        color="white"
      />
      <Circle ref={circle2} args={[0.1, 32]} color="white" />
      <Circle
        ref={circle3}
        args={[0.1, 32]}
        position={[0.5, 0, 0]}
        color="white"
      />
    </group>
  );
};

const App = () => {
  const [events, setEvents] = useState();
  const scrollArea = useRef();
  const onScroll = (e) => (state.top.current = e.target.scrollLeft);
  useEffect(() => {
    state.ref = scrollArea.current;
    state.effectFactor.current = 0;
    onScroll({ target: scrollArea.current });
  }, []);

  const computeOffsets = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    return { offsetX: offsetX - state.top.current, offsetY };
  };

  return (
    <>
      <Canvas
        concurrent
        orthographic
        camera={{ position: [0, 0, 500] }}
        raycaster={{ computeOffsets }}
        onCreated={(state) => {
          state.gl.setClearColor("#0c0f13");
          setEvents(state.events);
        }}
      >
        <Suspense
          fallback={
            <group scale={[100, 100, 0]}>
              <Loader />
            </group>
          }
        >
          <Content />
          <HeadsUpDisplay>
            <group position={[0, -8, 0]}>
              <Map />
            </group>
          </HeadsUpDisplay>
        </Suspense>
        <Effects />
      </Canvas>
      <div
        className="scrollArea"
        ref={scrollArea}
        onScroll={onScroll}
        {...events}
      >
        <div style={{ height: "100vh", width: `${state.pages * 100}vw` }} />
      </div>
    </>
  );
};

export default App;
