import { createRef } from "react";

const state = {
  sections: 6,
  pages: 5,
  navZoom: false,
  top: createRef(),
  effectFactor: createRef()
};

export default state;
