import React, { useRef, useState, useEffect } from "react";
import * as THREE from "three";
import { Math as _Math } from "three/src/Three";
import { useFrame } from "react-three-fiber";
import { useBlock } from "../blocks";
import "./CustomMaterial";
import state from "../store";

const Plane = ({
  color = "white",
  shift = 100,
  opacity = 1,
  args,
  map,
  prevMap,
  transition,
  ...props
}) => {
  const ref = useRef();
  const { contentMaxWidth: w } = useBlock();

  const [hover, setHover] = useState();
  useEffect(() => {
    if (props.onClick !== undefined)
      document.body.style.cursor = hover ? "pointer" : "auto";
  }, [hover, props.onClick]);

  const material = useRef();
  let last = state.top.current;
  useFrame(() => {
    const { top } = state;
    const targetFactor = Math.max(
      -(top.current - last) / shift, // scroll speed
      Math.sin(transition.current * Math.PI) // image transition
    );
    const factor = THREE.MathUtils.lerp(
      material.current.shift,
      targetFactor,
      0.1
    );

    material.current.shift = factor;
    if (factor > 0.01) {
      material.current.angle = _Math.randFloat(-Math.PI, Math.PI) * factor;
      material.current.seed = Math.random() * factor;
      if (Math.random() < 0.1) material.current.col_s = Math.random();
    }

    transition.current = THREE.MathUtils.lerp(transition.current, 1.0, 0.1);
    material.current.transition = transition.current;

    const scaleY = THREE.MathUtils.lerp(
      ref.current.scale.y,
      w * (map.image.height / map.image.width),
      0.1
    );
    ref.current.scale.set(w, scaleY, 1);

    last = top.current;
  });
  return (
    <mesh
      ref={ref}
      onPointerOver={(e) => {
        e.stopPropagation();
        setHover(true);
      }}
      onPointerOut={() => setHover(false)}
      {...props}
    >
      <planeBufferGeometry attach="geometry" args={[1, 1, 32, 32]} />
      <customMaterial
        ref={material}
        attach="material"
        color={color}
        map={map}
        prevMap={prevMap}
        transparent
        opacity={opacity}
      />
    </mesh>
  );
};

export default Plane;
